<template>
  <section>
    <div>
      <label for="">
        {{ $t("transaction_history") }}
      </label>
    </div>
    <SearchableTable
      class="searchable-table"
      :items="items"
      :commands="commands"
      :fields="fields"
      :searchEnabled="true"
      :pagination="true"
      :maxResult="(items || []).length"
    />
  </section>
</template>

<script>
import SearchableTable from "@/components/searchable-table.vue";
import ContractService from "@/services/contract.js";
/*
  date_time_event: "2020-04-30T16:09:39+00:00"
  contract_id: 48
   user: {id: 34, email: "mazoqui@gmail.com"}
   state: "Teste"
  valor_contrato: "R$ 0.00"
  balance_amount: "R$ 0.00"
  transaction_type: "Início de teste"
*/
let defaultData = function (self) {
  return {
    busy: false,
    items: null,
    dtini: null,
    dtend: null,
    fields: [
      //Usuário	Data	Histórico da transação	Saldo do contrato	Valor mensal do contrato	Status contrato
      {
        name: "date_time_event",
        title: "datetime",
        parser: (item) => {
          return self.$dt.format(item.date_time_event);
        }
      },
      {
        name: "user",
        title: "user",
        parser: (item) => {
          return (item && item.user && item.user.email) || "";
        }
      },
      {
        name: "transaction_type",
        title: "description"
      },
      {
        name: "state",
        title: "status"
      },
      {
        name: "contract_value",
        title: "contract_value",
        style: {
          "text-align": "right"
        }
      },
      {
        name: "balance_amount",
        title: "balance_amount",
        style: {
          "text-align": "right"
        }
      }
    ],
    commands: []
  };
};

export default {
  name: "UserPlanTransactions",
  components: {
    SearchableTable
  },
  props: {},
  data: function () {
    return defaultData(this);
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    isReady() {
      return this.contract != null;
    }
  },
  watch: {
    isReady(n, o) {
      if (n && !o) {
        this.setup();
      }
    },
    busy(n) {
      this.$emit("loading", n);
    }
  },
  methods: {
    fetch() {
      var self = this;
      if (!self.isReady) return;
      var query = {
        contract_id: this.contract.id,
        start: this.dtini.utc().format("YYYY-MM-DDTHH:mm:ss"),
        end: this.dtend.add(24, "hours").utc().format("YYYY-MM-DDTHH:mm:ss")
      };
      self.busy = true;
      this.service.history(query).then((response) => {
        self.busy = false;
        if (response && typeof response == "object") {
          response = response.sort((a, b) => {
            return (
              new Date(b.date_time_event).getTime() -
              new Date(a.date_time_event).getTime()
            );
          });
          self.$set(self, "items", JSON.parse(JSON.stringify(response)));
        }
      });
    },
    setup() {
      this.dtini = moment(this.contract.start_datetime);
      this.dtend = moment(new Date());
      this.fetch();
    }
  },
  beforeCreate() {
    this.service = new ContractService();
  },
  created() {},
  mounted() {
    if (this.isReady) {
      this.setup();
    }
  }
};
</script>

<style scoped>
.searchable-table {
  /* overflow-x: auto; */
}
</style>
